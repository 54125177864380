module.exports = [{
      plugin: require('/Users/nathanfoster/Documents/website/v4/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"wrapperStyle":"max-height: 1000px !important;"},
    },{
      plugin: require('/Users/nathanfoster/Documents/website/v4/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/nathanfoster/Documents/website/v4/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
